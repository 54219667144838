import { Injectable } from '@angular/core'
import { SocialSharing } from '@ionic-native/social-sharing/ngx'
import { Platform } from '@ionic/angular'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { get } from 'lodash'
import { first } from 'rxjs/operators'
import { IWord } from '@store/models/dictionary.model'
import { IJoke } from '@store/models/joke.model'
import { IPlayer } from '@store/models/player.model'
import { IYoutubePost } from '@store/models/youtube.model'
import { IAppState } from '@store/state'
import { IProverb } from '@app/store/models/proverb.model'

@Injectable({ providedIn: 'root' })
export class SocialSharingService {
    private message =
        'रेडियो कसूत, First Haryanvi OnlineOnline Radio Station App.'
    private subject = 'RadioKasoot Haryanvi Music Station on'
    private file = null
    private link = 'https://www.facebook.com/radiokasoot'

    constructor(
        private platform: Platform,
        private socialSharing: SocialSharing,
        private translateService: TranslateService,
        private store: Store<IAppState>
    ) {}

    shareStoreLink(linkType: string) {
        let messageString = ''

        if (linkType === 'ios') {
            messageString = 'SHARE.IOS_MESSAGE'
            this.subject = `${this.subject} iOS`
            this.link = 'https://itunes.apple.com/us/app/id1086344819'
        } else if (linkType === 'android') {
            messageString = 'SHARE.ANDROID_MESSAGE'
            this.subject = `${this.subject} Android`
            this.link =
                'https://market.android.com/details?id=com.radiokasoot.www'
        } else {
            messageString = 'SHARE.FACEBOOK_MESSAGE'
            this.subject = `${this.subject} facebook`
        }

        // share it now
        this.translateService
            .get(messageString)
            .pipe(first())
            .subscribe(value => {
                this.message = value
                this.share()
            })
    }

    shareJoke(joke: IJoke) {
        this.message = joke.title
        this.subject = 'RadioKasoot Joke'
        this.link = 'https://market.android.com/details?id=com.radiokasoot.www'
        this.share()
    }

    shareProverb(proverb: IProverb) {
        this.message = proverb.title
        this.subject = 'RadioKasoot Kahawat'
        this.link = 'https://market.android.com/details?id=com.radiokasoot.www'
        this.share()
    }

    shareWord(word: IWord) {
        const translationKeys: Array<string> = [
            'DICTIONARY.WORD',
            'DICTIONARY.ENGLISH_MEANING',
            'DICTIONARY.HINDI_MEANING',
            'DICTIONARY.SIMILAR_WORDS',
            'DICTIONARY.EXAMPLE',
        ]

        this.translateService
            .get(translationKeys)
            .subscribe((values: object[]) => {
                const name: string = values['DICTIONARY.WORD'],
                    english: string = values['DICTIONARY.ENGLISH_MEANING'],
                    hindi: string = values['DICTIONARY.HINDI_MEANING'],
                    similar: string = values['DICTIONARY.SIMILAR_WORDS'],
                    example: string = values['DICTIONARY.EXAMPLE'],
                    message = [
                        `${name}: ${word.name}`,
                        `${english}: ${word.english}`,
                        `${hindi}: ${word.hindi}`,
                        `${similar}: ${word.similar}`,
                        `${example}: ${word.example}`,
                    ].join('\n')

                this.message = message
                this.subject = 'RadioKasoot Dictionary'
                this.link =
                    'https://market.android.com/details?id=com.radiokasoot.www'
                this.share()
            })
    }

    shareVideo(post: IYoutubePost) {
        const videoUrl = encodeURI(
            'https://youtube.com/watch?v=' + post.id.videoId
        )
        this.message = [
            `Kasoot Video: ${videoUrl}`,
            'Watch more videos like this on Radio Kasoot',
            'Download Radio Kasoot app: ',
        ].join('\n')
        this.subject = 'RadioKasoot Video'
        this.link = 'https://market.android.com/details?id=com.radiokasoot.www'
        this.share()
    }

    share() {
        this.store
            .select('player')
            .pipe(first())
            .subscribe((player: IPlayer) => {
                if (!this.platform.is('android')) {
                    this.file = get(player, 'server.logoUrl')
                }

                // Share via native share sheet
                this.socialSharing.share(
                    this.message,
                    this.subject,
                    this.file,
                    this.link
                )
            })
    }
}
